import React from "react";

export default function Error404() {
  return (
    <div>
      <img
        className="min-h-screen object-cover"
        src="https://images.unsplash.com/photo-1578328819058-b69f3a3b0f6b?ixlib=rb-1.2.1&auto=format"
        alt="Not Found"
      />
    </div>
  );
}
